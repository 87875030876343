/// <reference types="react/canary" />

import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/components/ui/alert-dialog"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { AlertDialogPortal } from "@radix-ui/react-alert-dialog"
import { createRoute, Link, redirect, type AnyRoute, useRouter } from "@tanstack/react-router"
import { Car, PlusCircleIcon, Trash, Trash2, TrashIcon } from "lucide-react"
import { ofetch } from "ofetch"
import { startTransition, useMemo } from "react"
import type { Playground } from "shared/data/playground"
import type { ClientToolDef } from "shared/tool"
import { useOptimistic } from 'react';


export const homeRoute = <TParentRoute extends AnyRoute = AnyRoute>(parent: TParentRoute)=> {
    const r = createRoute({
    beforeLoad: async ({context}) => {
    const user = context.user;
    if (user === undefined) {
        return false;
    }
    if (!user){
        document.location.href = "/api/auth/signin"
        return null
    }
    if (!user.isAllowed){
        return redirect({
        to: "/not-allowed",
        throw: true,
        })
    }
    return true;
    },
    getParentRoute: () => parent,
    loader: async () => {
        const {playgrounds} = await ofetch<{playgrounds: Playground[]}>('/api/playgrounds')
        const {tools} = await ofetch<{tools: ClientToolDef[]}>('/api/tools')
        return {playgrounds, tools}
    },
    path: '/',
    component: ()=> {
        const {playgrounds, tools} = r.useLoaderData();
        return <PlaygroundsHome playgrounds={playgrounds} tools={tools} />
    },
    })
  return r;
}
export const PlaygroundsHome: React.FC<{playgrounds: Playground[], tools: ClientToolDef[] }> = ({playgrounds: _playgrounds, tools}) => {
    const newPlaygroundTools = useMemo(()=> tools.filter(t=> t.annotations["display-create-playground"]), [tools])
    const [playgrounds, playgroundsOptUpdate] = useOptimistic(_playgrounds, 
         (playgrounds, action: (p:Playground[])=>Playground[]   ) =>{
            return action(playgrounds);
        })
    const router = useRouter();
    return (
        <div className="w-screen p-4">
        <h1 className="text-3xl my-4">New Playground </h1>
        <div className="grid grid-cols-4 gap-4">
            <>
            {newPlaygroundTools.map(t=> <Link to={`/new-playground?tool=${t.name}`} key={t.name}>
                <Card className="cursor-pointer p-4">
                    <CardHeader className="h-32">
                        <CardTitle className="leading-8">{t.annotations["display-name"] ?? t.name}</CardTitle>
                        <CardDescription>{t.annotations["description"] ?? t.description}</CardDescription>
                    </CardHeader>
                    <CardFooter className="flex flex-col items-end">
                       
                        <PlusCircleIcon size={52}/>
                    </CardFooter>
                </Card>
            </Link>)}
            {/* Empty card to use no tool */}
            <Link to="/new-playground" className="cursor-pointer">
                <Card className="p-4 border-2 border-white">
                    <CardHeader className="h-32">
                        <CardTitle>Empty playground</CardTitle>
                        <CardDescription>Create a new playground</CardDescription>
                    </CardHeader>
                    <CardFooter className="flex flex-col items-end">
                       
                        <PlusCircleIcon size={52}/>
                    </CardFooter>
                </Card>
            </Link>
            </>
        </div>
        <h1 className="text-3xl my-4">My Playgrounds</h1>
        <div className="grid grid-cols-4 gap-4">
        {
            playgrounds.map(p=> <Link to="/p/$playgroundId" onClick={(e)=> {
                const target = e.target as Element;
                if (target && target.closest('[data-prevent-nav]')) {
                    e.preventDefault();
                  }
}} key={p.id} params={{playgroundId: p.id}}>  <Card className="cursor-pointer p-4" key={p.id}>
                <CardHeader className="h-32 flex-row justify-between items-center">
                    <CardTitle className="leading-8">{p.name}</CardTitle>
                </CardHeader>
                <CardContent>
                    
                </CardContent>
                <CardFooter className="flex flex-row justify-between">
                <CardDescription>
                    Created at {new Date(p.createdAt ?? new Date().getTime()).toLocaleString()}
                </CardDescription>
                <DeletePlaygroundButton playgroundId={p.id} onDelete={ async ()=>{
                        //playgroundsOptUpdate((playgrounds)=> playgrounds.filter(pp=> pp.id !== p.id))
                        //startTransition(async ()=> {
                        await ofetch(`/api/playgrounds/${p.id}`, {method: "DELETE"})
                        await router.invalidate()
                        //});
                    }
                    } />
                </CardFooter>
                </Card>
                </Link>)
        }
        </div>
        </div>
    );
}

const DeletePlaygroundButton: React.FC<{playgroundId: string, onDelete: ()=> void}> = ({playgroundId, onDelete}) => {
    return <AlertDialog>
  <AlertDialogTrigger asChild data-prevent-nav><Button size="icon" variant="outline"><Trash2/></Button></AlertDialogTrigger>
  <AlertDialogPortal>
  <AlertDialogContent>
    <AlertDialogHeader>
      <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
      <AlertDialogDescription>
        This action cannot be undone. This will permanently delete your playground and all resources inside.
      </AlertDialogDescription>
    </AlertDialogHeader>
    <AlertDialogFooter>
      <AlertDialogCancel data-prevent-nav>Cancel</AlertDialogCancel>
      <AlertDialogAction onClick={()=>onDelete()} data-prevent-nav>Continue</AlertDialogAction>
    </AlertDialogFooter>
  </AlertDialogContent>
    </AlertDialogPortal>
</AlertDialog>

}