import { useCallback, useEffect, useMemo, useRef, type FC } from "react";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";
import { Badge } from "@/components/ui/badge";
import { ResourcesPanel } from "./resources";
import { OutputsPanel } from "./outputs/outputs";
import { j_baseResources, j_resourceFocusMode, j_selectedOutputTab } from "./state";
import { useAtom } from "jotai";
import type { ImperativePanelHandle } from "react-resizable-panels";
import { ChevronLeftIcon, ChevronsRightIcon } from "lucide-react";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { useAtomCallback } from "jotai/utils";

export const PlaygroundHome: FC = () => {
  const [collapsed, setCollapsed] = useAtom(j_resourceFocusMode);
  const sidebarRef = useRef<ImperativePanelHandle>(null);
  
  const selectDefaultTab = useAtomCallback(useCallback((get,set)=> {
    const outputTab = get(j_selectedOutputTab);
    if (outputTab) return;
    const resources = get(j_baseResources).filter(x=>!x.parentResource);
    if (resources.length === 0) return;
    set(j_selectedOutputTab, resources[0].id);
  }, []));
  
  useEffect(() => {
    if (collapsed){
      selectDefaultTab();
    }
  }, [collapsed]);
  
  return (
    <ResizablePanelGroup
      direction="horizontal"
      className="h-full w-full"
    >
      <ResizablePanel
        ref={sidebarRef}
        className="rounded-2xl p-2 m-2 border-accent border-2 flex flex-col relative"
        onCollapse={() => setCollapsed(true)}
        onExpand={() => setCollapsed(false)}
        collapsible={true}
        collapsedSize={2}
        minSize={10}
        defaultSize={collapsed ? 2 : 20}
      >
        {collapsed ? <>
        <ChevronsRightIcon className="w-6 h-6 my-auto mx-auto cursor-pointer" onClick={()=> {
          sidebarRef.current?.expand(20);
        }} />
        </> : null }
        <div className={`${collapsed ? "hidden" : "flex flex-col overflow-hidden"}`}>
        <Badge className="self-start absolute top-0 left-2 z-10">Resources</Badge>

        <ScrollArea className="flex-1">
          <ScrollBar orientation="vertical" />
        <ResourcesPanel />
        </ScrollArea>
        </div>
      </ResizablePanel>
      <ResizableHandle withHandle />
      <ResizablePanel className="rounded-2xl p-2 m-2 border-accent border-2 overflow-y-auto flex flex-col relative">
        <OutputsPanel />
      </ResizablePanel>
    </ResizablePanelGroup>
  );
};