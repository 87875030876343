import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import type { ClientToolDef } from "shared/tool";
import { match, P } from "ts-pattern";
import { z } from "zod";
import { Button } from "./ui/button";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from "./ui/form";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { mapValues } from "remeda";



const detectRef = (playgroundId?: string) => (o: unknown ) => {
  if (typeof(o) !== "string" ){
    return o;
  }
  const result =o.match(/playgrounds\/([^/]+)\/resources\/([^/]+)\/raw/)
  return match(result)
    .with([P._, P.when((x)=> x === playgroundId), P.select()], (resourceId) => ({ $$resourceId: resourceId }))
    .with([P._, P.string, P.string], ([_,playground, resourceId]) => ({ $$resourceId: `${playground}/${resourceId}` }))
    .otherwise(() => o);
}

export const ResourceFromTool = ({
    tool,
    playgroundId,
    onSubmit,
  }: {
    tool: ClientToolDef;
    playgroundId?: string;
    onSubmit: (input: unknown) => void;
  }) => {
    const schema = tool.args;
  
    const form = useForm({
      resolver: zodResolver(schema),
    });
    
    return (
      <Form {...form}>
        <form onSubmit={form.handleSubmit((s)=> {
                let refArgs = mapValues(s, detectRef(playgroundId))
                onSubmit(refArgs)
        }, (err)=>console.log(err))} className="space-y-8">
          {Object.entries(schema.shape).map(([key, value]) => {
            const fieldType = value.isOptional() ? value._def.innerType : value;
            return (
              <FormField
                key={key}
                control={form.control}
                name={key}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{value.description || key}</FormLabel>
                    <FormControl>
                      {match(fieldType).with(P.instanceOf(z.ZodBoolean), ()=> 
                        <Input type="checkbox" {...field} onChange={x=> field.onChange(x.target.checked)} />
                      )
                      .with(P.instanceOf(z.ZodNumber), ()=>
                        <Input type="number" {...field} onChange={x=> field.onChange(x.target.valueAsNumber)} />
                      )
                      .otherwise(()=> 
                        <Textarea {...field} />
                      )}
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            );
          })}
          <Button type="submit">Generate</Button>
        </form>
      </Form>
    );
  };