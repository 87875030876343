import { match } from "ts-pattern";
import { atomFamily, useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import type { ResourceStreamTrace } from "shared/data/resource-stream";
import { P } from "ts-pattern";
import { scan, from, pipe, flatMap } from "../../../utils/readable-streams";
import { atom, useSetAtom, type Setter } from "jotai";
import { j_localResources, j_playgroundId, j_remoteResources, j_resourceById, j_selectedOutputTab, j_setOutputTab, useSelectOutputTab } from "./state";
import type { UIResource } from "shared/data/resource";
import type { ClientToolDef } from "shared/tool";
import { type ReadableStream, ReadableStream as WebReadableStream } from "../../../utils/readable-streams";

const extractMessages = (data: ReadableStream<Uint8Array>) => {
  const textDecoder = new TextDecoder();

  return pipe(
    data,
    scan(
      (acc, chunk) => {
        const contents = textDecoder.decode(chunk, { stream: true });

        const messages = [] as ResourceStreamTrace[];
        let { messageContent, openBrackets, inString, isPreviousBackslash } =
          acc;

        for (let i = 0; i < contents.length; i++) {
          const char = contents[i];

          if (char === '"' && !isPreviousBackslash) {
            inString = !inString;
          }

          if (inString) {
            messageContent += char;
          } else if (char === "{") {
            openBrackets += 1;
            messageContent += char;
          } else if (char === "}") {
            openBrackets -= 1;
            messageContent += char;

            if (openBrackets === 0) {
              try {
                messages.push(
                  JSON.parse(messageContent) as ResourceStreamTrace
                );
                messageContent = "";
              } catch (error) {
                console.warn("Incomplete or invalid JSON:", messageContent);
                // Keep the messageContent for the next chunk
              }
            }
          } else if (openBrackets > 0) {
            messageContent += char;
          }

          isPreviousBackslash = char === "\\" && !isPreviousBackslash;
        }

        return {
          openBrackets,
          inString,
          messageContent,
          messages,
          isPreviousBackslash,
        };
      },
      {
        openBrackets: 0,
        inString: false,
        messageContent: "",
        messages: [] as ResourceStreamTrace[],
        isPreviousBackslash: false,
      }
    ),
    flatMap((x) => from(x.messages))
  );
};

export function useApplyResourceStream() {
  return useSetAtom(j_applyResourceStream);
}

const j_runningProcesses_base = atom<Record<string, AbortController>>({});
export const j_runningProcesses = atomFamily((id: string) =>
  atom(
    (get) => {
      const running = get(j_runningProcesses_base);
      if (!running[id]) {
        return undefined;
      }
      return running[id];
    },
    (get, set, arg: AbortController | undefined) => {
      set(j_runningProcesses_base, (g) => {
        if (!arg) {
          const { [id]: _, ...rest } = g;
          return rest;
        }
        return {
          ...g,
          [id]: arg,
        };
      });
    }
  )
);

type WebsocketContext = {
  ws: WebSocket;
  resourceStream: ReadableStream<ResourceStreamTrace>;
  messagesToSend: Map<string, any>;
  abortController: AbortController;
}

const j_resourceSockets = atom<Record<string, WebsocketContext>>({});
export const j_resourceSocket = atomFamily((id: string) =>
  atom(
    (get) => {
      const sockets = get(j_resourceSockets);
      if (!sockets[id]) {
        return undefined;
      }
      return sockets[id];
    },
    (get, set, arg?: string) => {
      if (!arg) {
        set(j_resourceSockets, (g) => {
            const { [id]: _, ...rest } = g;
            return rest;
        })
        return;
      }
      const ws = new WebSocket(arg);
      const abortController = registerAbortController(id, set);
      abortController.signal.addEventListener("abort", () => {
        ws.close();
      })
      ws.onclose = () => {
        abortController.abort();
        set(j_resourceSockets, (g) => {
          const { [id]: _, ...rest } = g;
          return rest;
        });
        set(j_runningProcesses(id), undefined);
      }
      const messagesToSend = new Map<string, any>();
      const handleWaitMessage = async (resource: string, messageType: string) => {
        if (messagesToSend.has(`${messageType}_${resource}`)) {
          return messagesToSend.get(`${messageType}_${resource}`);
        }
        return await new Promise((resolve, reject) => {
          messagesToSend.set(`${messageType}_${resource}`, resolve);
        });
      }
      
      const resourceStream = new WebReadableStream<ResourceStreamTrace>({
        start(controller) {
          ws.onmessage = (event) => {
            const message = JSON.parse(event.data) as (ResourceStreamTrace | { type: "waiting", resource: string, messageType: string });
            if (message.type === "waiting") {
              handleWaitMessage(message.resource, message.messageType).then((x)=> {
                messagesToSend.delete(`${message.messageType}_${message.resource}`);
                return x;
              }).then((x) => ws.send(JSON.stringify(x)))
            } else {
              controller.enqueue(message);
            }

            ws.onclose = () => {
              controller.close();
            };
          }
        },
      });
      const wsContext = {
        ws,
        resourceStream,
        messagesToSend,
        abortController,
      }

      set(j_resourceSockets, (g) => {
        if (!arg) {
          const { [id]: _, ...rest } = g;
          return rest;
        }
        return {
          ...g,
          [id]:wsContext,
        };
      })
      return wsContext;
    }
  )
);

function resolveWaitingMessage(wsContext: WebsocketContext, resource: string, messageType: string, data: any) {
  const msg = wsContext.messagesToSend.get(`${messageType}_${resource}`);
  const payload = {
    messageType,
    resource,
    ...data,
  }
  if (msg && typeof msg === "function") {
    msg(payload);
  } else {
    wsContext.messagesToSend.set(`${messageType}_${resource}`, payload);
  }
}

export const j_connectResourceWS = atom(null, (get, set, resourceId: string, opts?:{
  query?: string,
  forceRecrate?: boolean,
}) => {
  let wsContext = get(j_resourceSocket(resourceId));
  if (wsContext?.ws.readyState === WebSocket.CLOSED) {
    wsContext = undefined;
  }
  if (opts?.forceRecrate && wsContext) {
    wsContext.abortController.abort();
    wsContext = undefined;
  }
  if (!wsContext) {
    wsContext = set(j_resourceSocket(resourceId), `/api/playgrounds/${get(j_playgroundId)}/resources/${resourceId}/ws${opts?.query ? `?${opts.query}` : ""}`);
    if (!wsContext) {
      throw new Error("Failed to create socket");
    }
    set(j_cleanResourceState,resourceId);
    set(j_applyResourceStream, wsContext.resourceStream).catch((e) => {
      console.error("Failed to apply resource stream", e);
      wsContext?.abortController.abort();
      set(j_resourceSocket(resourceId), undefined);
    });
    set(j_setOutputTab, resourceId);
  }
  return wsContext!;
});

export function useResourceWSApi() {
  const connect = useAtomCallback(async (get, set, resourceId: string, opts?:{
    query?: string,
    forceRecrate?: boolean,
  }) => {
    const wsContext = await set(j_connectResourceWS,resourceId, opts);
    return wsContext;
  });
  const create = useAtomCallback(
    useCallback(async (get, set, opts: {
      resourceId: string;
      tool: Pick<ClientToolDef, "name" | "outputType">;
      toolArgs: any;
      regenerate?: boolean;
    }) => {
      const wsContext = await connect(opts.resourceId, opts.regenerate ? {query: "regenerate", forceRecrate: true} : undefined);
      const generator = {
        tool: opts.tool.name,
        args: opts.toolArgs,
        outputType: opts.tool.outputType ?? "document",
      }
      //@ts-ignore
      set(j_resourceById(opts.resourceId),  (g) => ({
        ...g,
        status: "init",
        friendlyName: `${opts.tool.outputType}_${g.id}`,
        generator: generator,
      }))
      resolveWaitingMessage(wsContext, opts.resourceId, "create",{
        id: opts.resourceId,
        friendlyName: `${opts.tool.outputType}_${opts.resourceId}`,
        generator: generator
      });
    }, [])
  );

  const release = useAtomCallback(
    useCallback(async (get, set, { rootResourceId, triggeringResourceId }: { rootResourceId: string, triggeringResourceId: string }, msg: { args: unknown[], result: unknown }) => {
      const wsContext = await connect(rootResourceId);
      resolveWaitingMessage(wsContext!, triggeringResourceId, "release", msg);
    }, [])
  );

  const resume = useAtomCallback(
    useCallback(async (get, set, resourceId: string ) => {
      await connect(resourceId);
    }, [])
  );

  const regenerateFromCheckpoint = useAtomCallback(
    useCallback(async (get, set, rootResourceId:string,  checkpointId: string) => {
      await connect(rootResourceId, {forceRecrate: true, query: `regenerate=${checkpointId}` });
    }, [])
  );

  return {
    connect,
    create,
    resume,
    release,
    regenerateFromCheckpoint,
  }

}

const registerAbortController = (resourceId: string, set: Setter) => {
  const abortController = new AbortController();
  set(j_runningProcesses(resourceId), abortController);

  abortController.signal.addEventListener("abort", async () => {
    set(j_localResources, (x) => x.map(y => y.status === "generating" ? ({ ...y, status: "paused" }) : y));
    set(j_runningProcesses(resourceId), undefined);
  });
  return abortController
}

const j_cleanResourceState =  atom(null, (get, set, resourceId: string) => {
  function cleanResource(resourceId: string){
    const resource = get(j_resourceById(resourceId));
    const children = (resource.output?.childResources ?? []);
    set(j_resourceById(resourceId),  (g) => ({
      ...g,
      trace: [],
      output: {
        data: "",
        childResources: [],
      },
    }));
    for (let child of children){
      cleanResource(child);
    }
  }
  cleanResource(resourceId);
})

export function useResourceApi() {
  const selectOutputTab = useSelectOutputTab();

  const create = useAtomCallback(
    useCallback(
      async (
        get,
        set,
        opts: {
          resourceId: string;
          tool: ClientToolDef;
          toolArgs: any;
          regenerate?: boolean;
        }
      ) => {
        const toolConfig = {
          tool: opts.tool.name,
          args: opts.toolArgs,
          outputType: opts.tool.outputType ?? "document",
        };
        const { signal } = registerAbortController(opts.resourceId, set);
        const resource = set(j_resourceById(opts.resourceId), (g) => ({
          ...g,
          friendlyName: `${toolConfig.outputType}_${g.id}`,
          generator: toolConfig,
          trace: [],
          status: "generating",
          output: {
            data: "",
            childResources: [],
          },
        }));
        selectOutputTab(opts.resourceId);

        const playgroundId = get(j_playgroundId);
        const method = opts.regenerate ? "PUT" : "POST";
        const url = opts.regenerate ? `/api/playgrounds/${playgroundId}/resources/${opts.resourceId}` : `/api/playgrounds/${playgroundId}/resources`;
        const res = await fetch(url, {
          method: method,
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: resource.id,
            friendlyName: resource.friendlyName,
            generator: toolConfig,
          }),
          signal: signal,
        });

        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        const data = await res.body!;
        await set(j_applyResourceStream, extractMessages(data)).catch((e) => {
          if (e?.name === "AbortError") {
            get(j_runningProcesses(opts.resourceId))?.abort();
          }
        })
      },
      []
    )
  );

  const resume = useAtomCallback(
    useCallback(async (get, set, resourceId: string) => {
      const { signal } = registerAbortController(resourceId, set);

      const playgroundId = get(j_playgroundId);
      await set(j_remoteResources);
      set(j_localResources, x => x.filter(y => y.status === "draft"));
      const existing = get(j_resourceById(resourceId));
      if (existing.status !== "paused" && existing.status !== "generating") {
        return;
      }
      set(j_resourceById(resourceId), (g) => ({
        ...g,
        status: "generating",
        output: {
          data: "",
          childResources: [],
        },
        trace: []
      }));
      selectOutputTab(resourceId);


      const res = await fetch(
        `/api/playgrounds/${playgroundId}/resources/${resourceId}/resume`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          signal: signal,
        }
      );
      const data = await res.body!;
      await set(j_applyResourceStream, extractMessages(data)).catch((e) => {
        if (e?.name === "AbortError") {
          get(j_runningProcesses(resourceId))?.abort();
        }
      });
    }, [])
  );

  const regenerateFromCheckpoint = useAtomCallback(
    useCallback(async (get, set, resourceId: string, checkpointId: string) => {
      const { signal } = registerAbortController(resourceId, set);

      const playgroundId = get(j_playgroundId);
      //await set(j_remoteResources);
      set(j_localResources, x => x.filter(y => y.status === "draft"));
      set(j_resourceById(resourceId), (g) => ({
        ...g,
        status: "generating",
      }));

      const res = await fetch(
        `/api/playgrounds/${playgroundId}/resources/${resourceId}/regenerate?checkpoint=${checkpointId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          signal: signal,
        }
      );
      const data = await res.body!
      await set(j_applyResourceStream, extractMessages(data)).catch((e) => {
        if (e?.name === "AbortError") {
          get(j_runningProcesses(resourceId))?.abort();
        }
      });
    }, [])
  );

  const release = useAtomCallback(
    useCallback(async (get, set, { rootResourceId, triggeringResourceId }: { rootResourceId: string, triggeringResourceId: string }, msg: { args: unknown[], result: unknown }) => {
      const { signal } = registerAbortController(rootResourceId, set);
      const playgroundId = get(j_playgroundId);

      if (triggeringResourceId) {
        let u = get(j_resourceById(triggeringResourceId))
        for (; ;) {
          set(j_resourceById(u.id), (g) => ({
            ...g,
            status: "generating",
          }));
          if (!u.parentResource || u.parentResource === u.id) {
            break;
          }
          u = get(j_resourceById(u.parentResource));
        }
      } else {
        set(j_resourceById(rootResourceId), (g) => ({
          ...g,
          status: "generating",
        }));
      }

      const res = await fetch(
        `/api/playgrounds/${playgroundId}/resources/${rootResourceId}/release`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(msg),
          signal: signal,
        }
      );
      const data = await res.body!;
      await set(j_applyResourceStream, data).catch((e) => {
        if (e?.name === "AbortError") {
          get(j_runningProcesses(rootResourceId))?.abort();
        }
      });
    }, [])
  );

  return {
    regenerateFromCheckpoint,
    create,
    resume,
    release,
  };
}

const j_applyResourceStream = atom(
  null,
  async (get, set, data: ReadableStream<ResourceStreamTrace>) => {

    for await (const message of data) {
      if (message.type === "create-subresource") {
        set(j_localResources, (g) => [...g.filter(x => x.id !== message.data.id), message.data as UIResource]);
      }
      try {
        j_resourceById(message.resource);
      } catch (error) {
        // resource not found
        // attempt to fix by refreshing remote resources
        await set(j_remoteResources);
      }
      set(j_resourceById(message.resource), (s) => {

        const newResource = {
          ...s,
          status: "generating",
          trace: (s.trace ?? []),
        } as UIResource;

        if (message.type === "log" || message.type === "data" || message.type === "progress") {
          const [prevTrace] = newResource.trace.slice(-1);
          if (prevTrace?.type === message.type) {
            prevTrace.data += message.data;
          } else {
            newResource.trace = [...newResource.trace, message];
          }
        } else {
          newResource.trace = [...newResource.trace, message];
        }

        return match(message)
          .with({ type: "update-resource-name" }, (x) => ({
            ...newResource,
            friendlyName: x.data,
          }))
          .with(
            { type: "create-subresource", data: { id: P.nonNullable } },
            (x) => ({
              ...newResource,
              output: {
                ...newResource.output,
                childResources: [...new Set([
                  ...(newResource.output?.childResources ?? []),
                  x.data.id,
                ])],
              },
            })
          )
          /*
          .with({ type: "data" }, (x) => ({
            ...newResource,
            output: {
              ...newResource.output,
              data:  (x.flush ? "" : (newResource.output?.data ?? "")) + x.data,
            },
          }))*/
          .with({ type: "error" }, (x) => ({
            ...newResource,
            status: "error",
            output: {
              ...newResource.output,
              data: x.data,
            },
          }))
          .with({ type: "done" }, (x) => ({
            ...newResource,
            status: "done",
            output: {
              ...newResource.output,
              data: x.data,
            },
          }))
          .with({ type: "suspend" }, (x) => ({
            ...newResource,
            status: "suspended",
          }))
          .with({ type: "abort" }, (x) => ({
            ...newResource,
            status: "paused",
          }))
          .otherwise(() => newResource) as UIResource;
      });
    }
  }
);

export const j_suspendedCauseResource = atom(
  null,
  (get, set, resourceId: string): UIResource | null => {
    return (function suspendedCauseResource(resourceId: string): UIResource | null {
      const resource = get(j_resourceById(resourceId));
      if (resource.status !== "suspended") {
        return null
      }
      const children = (resource.output?.childResources ?? []).map(x => get(j_resourceById(x))).filter(x => x?.status === "suspended");
      if (resource.status === "suspended" && children?.length === 0) {
        return resource;
      }
      return children.map((x) => suspendedCauseResource(x.id))[0];
    })(resourceId);

  }
)

const j_rootParent = atom(
  null,
  (get, set, resourceId: string): UIResource | null => {
    return (function rootParent(resourceId: string): UIResource | null {
      const resource = get(j_resourceById(resourceId));
      if (!resource.parentResource) {
        return resource;
      }
      return rootParent(resource.parentResource);
    })(resourceId);
  }
)

export const useGetRootParentResource = (resourceId: string) => {
  return useSetAtom(j_rootParent)(resourceId);
}

export const useGetSuspendCauseResource = (resourceId: string) => {
  return useSetAtom(j_suspendedCauseResource)(resourceId);
};